import styled from 'styled-components'

export const ImageContainer = styled.div<{ width: string }>`
  width: ${props => props.width};
  height: auto;

  & > img {
    max-width: 100%;
    height: auto;
  }
`
