import styled from 'styled-components'

import { Flex } from '../../../layout'

export const StyledMenuHeader = styled(Flex)`
  background-color: ${props => props.theme.menu.colors.background.base};
  height: ${props => props.theme.menu.dimensions.desktop.footerHeight};
  padding: ${props => `0 ${props.theme.spacings.medium} 0 ${props.theme.spacings.medium}`};
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
`

export const StyledMenuHeaderImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`

export const StyledMenuHeaderImage = styled.img`
  ${props => !!props.onClick && 'cursor: pointer;'}
  height: auto;
  margin: auto 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
`
