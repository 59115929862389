import React, { lazy, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import FullscreenLoader from 'src/components/base/fullscreenLoader/FullscreenLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import RequireAuth from 'src/components/presentationals/auth/RequireAuth'
import RequireUnAuth from 'src/components/presentationals/auth/RequireUnAuth'
import ErrorBoundary from 'src/components/presentationals/shared/errors/ErrorBoundary'
import { useUser } from 'src/contexts/userContext'
import { routerUserRedirects } from 'src/utils/router'

import { CURRENT_MEMBERSHIP_ID_QUERY_PARAM } from './constants/constants'
import { MembershipProvider } from './contexts/MembershipContext'
import { useSessionStorageContext } from './contexts/SessionStorageContext'
import { getUserType } from './contexts/userContext'
import { UserType } from './types'

const AsyncBuyerDashboard = lazy(
  () => import('./components/presentationals/buyer-dashboard/BuyerDashboard')
)

const AsyncSellerDashboard = lazy(
  () => import('./components/presentationals/seller-dashboard/SellerDashboard')
)

const AsyncNotifications = lazy(() => import('./components/containers/Notifications'))

const AsyncLoadingBar = lazy(() => import('./components/containers/LoadingBar'))

const AsyncLogin = lazy(() => import('./components/presentationals/auth/Login'))

const AsyncBuyerSignup = lazy(() => import('./components/presentationals/auth/buyer-signup'))

const AsyncSellerSignup = lazy(() => import('./components/presentationals/auth/seller-signup'))

const AsyncSignupDone = lazy(() => import('./components/presentationals/auth/SignupDone'))

const AsyncForgotPassword = lazy(
  () => import('./components/presentationals/auth/forgot-password/ForgotPassword')
)

const AsyncForgotPasswordDone = lazy(
  () => import('./components/presentationals/auth/forgot-password/ForgotPasswordDone')
)

const AsyncResetPassword = lazy(
  () => import('./components/presentationals/auth/reset-password/ResetPassword')
)

const AsyncResetPasswordDone = lazy(
  () => import('./components/presentationals/auth/reset-password/ResetPasswordDone')
)

const AsyncDirectory = lazy(() => import('./directory/Directory'))

const AsyncGcpLandingPage = lazy(() => import('./landing-pages/GcpLandingPage/GcpLandingPage'))

const buyerPath = '/:locale/:userType(buyer)'
const sellerPath = '/:locale/:userType(seller)'
const buyerOrSellerPath = '/:locale/:userType(buyer|seller)'
const stopImpPath = '/:locale/imp/stop'
const loginPath = '/:locale/accounts/login'
const buyerSignupPath = '/:locale/accounts/buyer'
const sellerSignupPath = '/:locale/accounts/seller'
const signupDonePath = '/:locale/accounts/:userType(buyer|seller)/done'
const forgotPasswordPath = '/:locale/accounts/password_reset'
const forgotPasswordDonePath = '/:locale/accounts/password_reset/done'
const resetPasswordPath = '/:locale/accounts/reset'
const resetPasswordDonePath = '/:locale/accounts/reset/done'
const directoryPath = '/:locale/catalog'
const logoutPath = '/:locale/logout'
const logoutProfilePath = '/:locale/logout-profile'
const gcpLandingPage = '/:locale/register'

const Routes = () => {
  const { pathname, search } = useLocation()
  const user = useUser()
  const { currentMembershipId, setCurrentMembershipId } = useSessionStorageContext()
  const location = useLocation()
  const matchLocale = useRouteMatch({ path: '/:locale' })

  const params = new URLSearchParams(location.search)
  const currentMembershipIdFromUrl = parseInt(params.get(CURRENT_MEMBERSHIP_ID_QUERY_PARAM) ?? '')
  const currentMembershipFromUrl = user?.memberships.find(
    membership => membership.id === currentMembershipIdFromUrl
  )

  const currentMembership = user?.memberships.find(
    membership => membership.id === currentMembershipId
  )

  useEffect(() => {
    const isCurrentMembershipNotSet = !currentMembership
    const isCurrentMembershipNotFoundAmongUserMemberships =
      !!currentMembershipId && isCurrentMembershipNotSet

    if (currentMembershipFromUrl) {
      setCurrentMembershipId(currentMembershipFromUrl.id)
    } else if (user && (!currentMembershipId || isCurrentMembershipNotFoundAmongUserMemberships)) {
      const defaultMembershipId = user.memberships.find(membership => membership.isDefault)?.id

      if (defaultMembershipId) {
        setCurrentMembershipId(defaultMembershipId)
      }
    }
  }, [JSON.stringify(user)])

  const matchBuyerOrSellerPath = useRouteMatch({ path: buyerOrSellerPath })

  const userType = getUserType({
    membership: currentMembershipFromUrl || currentMembership,
    user,
  })

  return matchLocale ? (
    <>
      <Switch>
        <Route path={loginPath}>
          <RequireUnAuth>
            <AsyncLogin />
          </RequireUnAuth>
        </Route>
        <Route exact path={forgotPasswordPath}>
          <AsyncForgotPassword />
        </Route>
        <Route exact path={forgotPasswordDonePath}>
          <AsyncForgotPasswordDone />
        </Route>
        <Route exact path={resetPasswordPath}>
          <AsyncResetPassword />
        </Route>
        <Route exact path={resetPasswordDonePath}>
          <AsyncResetPasswordDone />
        </Route>
        <Route exact path={buyerSignupPath}>
          <AsyncBuyerSignup />
        </Route>
        <Route exact path={sellerSignupPath}>
          <AsyncSellerSignup />
        </Route>
        <Route exact path={signupDonePath}>
          <AsyncSignupDone />
        </Route>
        <Route path={directoryPath}>
          <AsyncDirectory />
        </Route>
        <Route exact path={gcpLandingPage}>
          <AsyncGcpLandingPage />
        </Route>

        {!user && (
          <Route
            path="/:locale"
            children={({ match }) =>
              !!match ? (
                <Redirect
                  to={{
                    pathname: `/${match.params.locale}/accounts/login`,
                    search: `?next=${pathname}${search}`,
                  }}
                />
              ) : null
            }
          />
        )}

        {!!user && userType === UserType.ADMIN && (
          <Route exact path="*">
            <RequireAuth>
              <FullscreenLoader text={gettext('Opening admin')} />
            </RequireAuth>
          </Route>
        )}
        {!!user && !!currentMembership && userType === UserType.BUYER && (
          <Route path={buyerPath}>
            <RequireAuth>
              <MembershipProvider membership={currentMembership}>
                <AsyncBuyerDashboard />
              </MembershipProvider>
            </RequireAuth>
          </Route>
        )}
        {!!user && !!currentMembership && userType === UserType.SELLER && (
          <Route path={sellerPath}>
            <RequireAuth>
              <MembershipProvider membership={currentMembership}>
                <AsyncSellerDashboard />
              </MembershipProvider>
            </RequireAuth>
          </Route>
        )}

        <Route
          exact
          path="/:locale"
          children={({ match }) =>
            !!match ? (
              <Redirect
                to={{ pathname: `/${match.params.locale}${routerUserRedirects[userType]}`, search }}
              />
            ) : null
          }
        />

        <Route exact path={logoutPath}>
          <FullscreenLoader text={gettext('Logging out')} />
        </Route>

        <Route exact path={logoutProfilePath}>
          <FullscreenLoader text={gettext('Logging out of profile')} />
        </Route>

        <Route exact path={stopImpPath}>
          <RequireAuth>
            <FullscreenLoader text={gettext('Impersonation')} />
          </RequireAuth>
        </Route>

        <Route path="*">
          <ErrorBoundary>
            <NotFound />
          </ErrorBoundary>
        </Route>
      </Switch>
      {matchBuyerOrSellerPath && <AsyncLoadingBar />}
      <AsyncNotifications />
    </>
  ) : null
}

export default Routes
